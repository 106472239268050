import React, { Component } from 'react';
import CallToAction from '../templates/CallToAction/CallToAction';
import mapSurveyResponses from '../../../helpers/mapSurveyQuestions';

class TestLong extends Component {

    render() { 

        let {questionId, question, placeholder, response, charLimit, callToAction} = this.props.data;

        return ( 
            <div>
                <h3>
                    <span dangerouslySetInnerHTML={{__html: mapSurveyResponses(question, this.props.fullSurvey)}}></span>
                </h3>
                <div className="mt-3">
                    <textarea className="form-control" maxLength={charLimit > 0 ? charLimit : 250} placeholder={placeholder} onChange={event => this.props.handleChange(questionId, event.target.value)} value={response}></textarea>
                </div>
                { callToAction !== '' &&
                    <CallToAction lastQuestion={this.props.lastQuestion} nextStep={this.props.nextStep} callToAction={callToAction} getNextButton={this.props.getNextButton} />
                }
            </div>
        );
    }
}
 
export default TestLong;