import _ from "lodash";
import { Component } from "react";
import './RedirectListItem.css'
import mapSurveyResponses from "../../../helpers/mapSurveyQuestions";

class RedirectList extends Component {
    state = {
        submitting: false
    }

    saveAndContinue = async (questionId, value) => {
        const nextStep = this.props.nextStep

        if (!this.props.lastQuestion){
            setTimeout(function(){
                nextStep(false)
            }, 150)
        }
    }

    save = async (questionId, value) => {
        this.props.handleChange(questionId, value, true);
    }

    render() {

        let { questionId, question, options, negCallToAction } = this.props.data;

        return (
            <div>
                <h3>
                    <span dangerouslySetInnerHTML={{ __html: mapSurveyResponses(question, this.props.fullSurvey) }}></span>
                </h3>
                <div className="mt-3">
                    {
                        options.map(option => {
                            
                            return <div className="redirectListItem card mb-2" key={option.description}>
                                <div className="redirectListItem-flex card-body d-flex p-0">
                                    {option.image &&
                                        <a
                                            className="redirectListItem-logo rounded-left"
                                            alt={'Image'}
                                            href={`${mapSurveyResponses(option.url, this.props.fullSurvey)}`}
                                            target="_blank"
                                            style={{backgroundImage: `URL('${option.image}')`}}>
                                        </a>
                                    }
                                    <div className="text-left p-2">
                                        <span dangerouslySetInnerHTML={{ __html: _.unescape(option.description) }}></span>
                                        <div className="redirectListItem-cta mt-2">
                                            <a className="redirectListItem-button btn btn-sm btn-primary" onClick={() => this.save(questionId, option.value)} href={`${mapSurveyResponses(option.url, this.props.fullSurvey)}`} target="_blank">Go Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
                <div className="mt-3">
                    <button className="btn btn-lnk text-secondary" onClick={() => {this.saveAndContinue(questionId,"No Thanks")}}><u>{negCallToAction !== '' ? negCallToAction : 'No Thanks!'}</u></button>
                </div>
            </div>
        )
    }

}

export default RedirectList