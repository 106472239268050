import React, { Component } from 'react';
import CallToAction from '../templates/CallToAction/CallToAction';
import mapSurveyResponses from '../../../helpers/mapSurveyQuestions';

class TextShort extends Component {
    
    render() { 

        let {questionId, question, placeholder, response, charLimit, disclaimer, callToAction} = this.props.data;

        return ( 
            <div>
                <h3>
                    <span dangerouslySetInnerHTML={{__html: mapSurveyResponses(question, this.props.fullSurvey)}}></span>
                </h3>
                <div className="mt-3">
                    <input className="form-control" maxLength={charLimit > 0 ? charLimit : 250} type="text" placeholder={placeholder} onChange={event => this.props.handleChange(questionId, event.target.value)} value={response} />
                </div>
                { callToAction !== '' &&
                    <CallToAction lastQuestion={this.props.lastQuestion} nextStep={this.props.nextStep} callToAction={callToAction} getNextButton={this.props.getNextButton} />
                }
                <div className="mt-5">
                    <p dangerouslySetInnerHTML={{ __html: mapSurveyResponses(disclaimer, this.props.fullSurvey) }} className="text-info text-small text-center"></p>
                </div>
            </div>
        );
    }
}
 
export default TextShort;