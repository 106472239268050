import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import mapSurveyResponses from '../../../helpers/mapSurveyQuestions';

class YesNo extends Component {

    state = { 
        submitting: false
    }

    saveAndContinue = async (questionId, value) => {
        
        this.setState({submitting:true})

        await this.props.handleChange(questionId, value);

        const nextStep = this.props.nextStep

        if (!this.props.lastQuestion){
            setTimeout(function(){                
                nextStep(false)               
            }, 150)
        }
    }

    render() {

        let {questionId, question, response, disclaimer} = this.props.data;

        let classes = 'btn btn-lg px-5 d-block w-100 mw-200px';
        let yesClass = response === 'Yes' ? `${classes} btn-primary` : `${classes} btn-secondary`;
        let noClass = response === 'No' ? `${classes} btn-primary` : `${classes} btn-secondary`;        

        return ( 
            <div>
                <h3>
                    <span dangerouslySetInnerHTML={{__html: mapSurveyResponses(question, this.props.fullSurvey)}}></span>
                </h3>
                <div className="mt-3">
                    {!this.state.submitting ?
                        <React.Fragment>
                            <button className={yesClass} style={{margin: '0.5rem auto 0.5rem auto'}} name="Yes" value="Yes" onClick={() => {this.saveAndContinue(questionId,"Yes")}}>Yes</button>
                            <button className={noClass} style={{margin: '0.5rem auto 0.5rem auto'}} name="No" value="No" onClick={() => {this.saveAndContinue(questionId,"No")}}>No</button>
                        </React.Fragment>
                    :
                        <React.Fragment>
                            <button className={yesClass} style={{margin: '0.5rem auto 0.5rem auto'}} name="Yes" value="Yes" disabled>Yes</button>
                            <button className={noClass} style={{margin: '0.5rem auto 0.5rem auto'}} name="No" value="No" disabled>No</button>
                            <div style={{position:'absolute', top:'50%', left:'48.5%'}}><FontAwesomeIcon icon="spinner" spin size="2x" className="text-muted" /></div>
                        </React.Fragment>
                    }                    
                </div>
                <div className="mt-5">
                    <p dangerouslySetInnerHTML={{ __html: mapSurveyResponses(disclaimer, this.props.fullSurvey) }} className="text-info text-small text-center"></p>
                </div>
            </div>
        );
    }
}
 
export default YesNo;