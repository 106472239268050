import _ from 'lodash'

const mapSurveyResponses = (string, fullSurvey) => {
    // This function will look for occurences of #xxx#

    let regex = /#([a-zA-Z0-9.]*?)#/gm
    
    // This is an array of all matched. Now we loop through each one, get the question, and find the response, if any.
    let matched = string.match(regex)

    let finalString = string;

    if (matched){
        matched.forEach( item => {
            // Find the item.
            // The item format is #q0.phone#
    
            // Split the item into question number, then name
            let splitItem = item.replace(/#/g,"").split(".")            
    
            let orderNumber = splitItem[0] ? splitItem[0].substr(1,2) : false;
            let questionName = splitItem[1] ? splitItem[1] : false;            
    
            if (orderNumber){
                // Does the question exist.
                let existingQuestion = fullSurvey.find(question => parseInt(question.order) === parseInt(orderNumber))            
    
                if (existingQuestion){
                    // If found, check if there is a response. If the response is a string, use that value. If the response is an object, find the corresponding object. 
                    if (questionName){
                        // We were given a key to check, the response must be an object then.
                        if (!Array.isArray(existingQuestion.response) && typeof existingQuestion.response === 'object'){
                            // Check if the questionName exists in the object.
                            if (existingQuestion.response[questionName]){
                                finalString = _.replace(finalString, new RegExp(_.escapeRegExp(item), 'g'), existingQuestion.response[questionName])    
                            } else {
                                // Could not find the matching key
                                finalString = _.replace(finalString, new RegExp(_.escapeRegExp(item), 'g'), '')        
                            }
                        } else {
                            // It wasn't an object. We return nothing since it wasn't found.
                            finalString = _.replace(finalString, new RegExp(_.escapeRegExp(item), 'g'), '')        
                        }
                    } else {
                        // We are not given a key, it must be a string value then.
                        if (typeof existingQuestion.response == 'string' || typeof existingQuestion.response == 'number'){
                            finalString = _.replace(finalString, new RegExp(_.escapeRegExp(item), 'g'), existingQuestion.response)
                        } else {
                            // It's not a string, something is wrong.
                            finalString = _.replace(finalString, new RegExp(_.escapeRegExp(item), 'g'), '')    
                        }
                    }
                } else {
                    // Question number (order) doesn't exist
                    finalString = _.replace(finalString, new RegExp(_.escapeRegExp(item), 'g'), '')
                }
            } else {
                // Something is wrong with the macro
                finalString = _.replace(finalString, new RegExp(_.escapeRegExp(item), 'g'), '')
            }
            
        })
    }

    return finalString
}

export default mapSurveyResponses;