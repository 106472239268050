import React, { Component } from 'react';
import Survey from '../../../templates/Survey/Survey';

import './IndexLayout3.css'

class IndexLayout3 extends Component {
    state = { 
        backgroundImage: ''
    }

    componentDidMount(){
        if (this.props.design.main.leftSide.backgroundImage.length > 0){
            fetch(`${this.props.design.main.leftSide.backgroundImage}`, {
                method: 'GET',
                credentials: 'include'                
            })
                .then(response => response.blob())
                .then(blob => {
                    this.setState({ backgroundImage: URL.createObjectURL(blob) })
                })
                .catch(err=>console.log(err))
        }
    }

    getLeftSideStyles = () => {
        let design = this.props.design;
        let style = {backgroundColor: design.main.leftSide.backgroundColor};

        if (this.state.backgroundImage !== ''){
            style = {...style, backgroundImage: `URL(${this.state.backgroundImage})`, backgroundSize: 'cover'}
        }

        return style;
    }

    getSurveyContainerClasses = () => {
        return this.props.page === 1 ? 'col-md-7 h-100 layout3' : 'col-md-12 h-100 layout3'
    }

    getSurveyParentClasses = () => {
        return this.props.page === 1 ? "p-3 h-100 w-100 mb-survey-3 mb-survey-3-p1":"h-100 w-100 mb-survey-3" 
    }

    render() { 
        return (
            <div className="container-fluid">
                <div className="row h-100">
                    { this.props.page === 1 &&
                        <div className="col-md-5 h-100" style={this.getLeftSideStyles()}>
                            <div className="p-3 h-100">
                                <div className="d-flex align-items-center h-100">
                                    <div className="m-auto">
                                        <div className="mb-maxw-500">
                                            <h2 dangerouslySetInnerHTML={{__html: this.props.content.headerText}} className={this.props.getHeaderClasses()} style={this.props.getHeaderStyles()}></h2>
                                            {this.props.content.descriptionText.length > 0 &&
                                                <p dangerouslySetInnerHTML={{__html: this.props.content.descriptionText}} className={this.props.getDescriptionClasses()} style={this.props.getDescriptionStyles()}></p>    
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className={this.getSurveyContainerClasses()}>
                        <div className={this.getSurveyParentClasses()}>                            
                            <Survey surveyId={this.props.surveyId} mbClass="" layout={this.props.layout} propertyId={this.props.propertyId} siteId={this.props.siteId} survey={this.props.survey} avtc={this.props.avtc} mbi={this.props.mbi} pushnamiOverride={this.props.pushnamiOverride} loadIframe={this.props.loadIframe} changePage={this.props.changePage}/>
                        </div> 
                    </div>
                </div>
            </div>
        );
    }
}
 
export default IndexLayout3;