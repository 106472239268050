import React, { Component } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import CallToAction from '../templates/CallToAction/CallToAction';
import mapSurveyResponses from '../../../helpers/mapSurveyQuestions';

class ShortFormAddressPhone extends Component {
    state = {  }
    render() { 

        let {questionId, disclaimer, question, placeholder, response, callToAction} = this.props.data;
        let address;
        let phone;        

        if (typeof response === 'object'){
            address = !response.address ? "" : response.address;
            phone = !response.phone? "" : response.phone;
        }

        return ( 
            <div>
                {question !== '' &&
                    <div className="alert alert-info">
                        <h3>
                            <span dangerouslySetInnerHTML={{__html: mapSurveyResponses(question, this.props.fullSurvey)}}></span>
                        </h3>
                    </div>
                }
                <div className="row mt-4">
                    <div className="col-md-6">
                        <h3 className="text-left">                            
                            Street Address
                        </h3>
                        <div className="mt-3">
                            <input maxLength={250} className="form-control" type="text" onChange={event => this.props.handleChange(questionId, event.target.value, false, true, 'address')} defaultValue={address} />
                        </div>
                    </div>
                    <div className="col-md-6 short-form-group">
                        <h3 className="text-left">
                            Phone
                        </h3>
                        <div className="mt-3">
                            <PhoneInput
                                country={'us'}                                
                                value={phone}
                                onChange={phone => this.props.handleChange(questionId, phone, false, true, 'phone')}
                                />                            
                        </div>
                    </div>                    
                </div>  
                { callToAction !== '' &&
                    <CallToAction fullSurvey={this.props.fullSurvey} lastQuestion={this.props.lastQuestion} nextStep={this.props.nextStep} callToAction={callToAction} getNextButton={this.props.getNextButton} redirect={placeholder} />
                }
                <div className="mt-5">
                    <p dangerouslySetInnerHTML={{ __html: mapSurveyResponses(disclaimer, this.props.fullSurvey) }} className="text-info text-small text-center"></p>
                </div>                                       
            </div>
        );
    }
}
 
export default ShortFormAddressPhone;