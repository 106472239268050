import React, { Component } from 'react';
import Navbar from '../Navbar/Navbar';
import { Link } from 'react-router-dom';

import './Layout.css';

class Layout extends Component {

    state = {
        backgroundImage: ''
    }

    componentDidMount() {
        if (this.props.design.main.backgroundImage.length > 0){
            fetch(`${this.props.design.main.backgroundImage}`, {
                method: 'GET',
                credentials: 'include'                
            })
                .then(response => response.blob())
                .then(blob => {
                    this.setState({ backgroundImage: URL.createObjectURL(blob) })
                })
                .catch(err=>console.log(err))
        }
    }

    getMainStyles = (design) => {        
        let style = {backgroundColor: design.main.backgroundColor, backgroundImage:`URL(${this.state.backgroundImage})`, backgroundSize: 'cover'}
        return style;
    }

    getFooterStyles = (design) => {
        let style = {backgroundColor: design.footer.backgroundColor, color: design.footer.color}
        return style;
    }

    getMainLayout = () => {
        let layout = `Content layout_${this.props.layout}`;
        return layout;
    }

    render() { 
        return ( 
            <main className={this.getMainLayout()} style={this.getMainStyles(this.props.design)}>
                <Navbar design={this.props.design}></Navbar>
                {this.props.children}
                <div className="footer p-5" style={this.getFooterStyles(this.props.design)}>
                    <div dangerouslySetInnerHTML={{__html: `${this.props.footerContent}`}}></div>
                    <div className="mt-3">
                        <ul className="footer-links">
                            <li className="footer-link"><Link target="_blank" rel="noreferrer noopener" className="text-info" to="/terms">Terms of Use</Link></li>
                            <li className="footer-link"><Link target="_blank" rel="noreferrer noopener" className="text-info" to="/privacy">Privacy</Link></li>
                            <li className="footer-link"><Link target="_blank" rel="noreferrer noopener" className="text-info" to="/contact">Contact</Link></li>
                        </ul>
                    </div>
                </div>                
            </main>
        );
    }
}
 
export default Layout;