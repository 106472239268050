import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const Jornaya = ({jornayaToken, onGetUniversalLeadId, leadId}) => {

    useEffect(()=>{
        if (typeof window !== "undefined" && leadId === ""){
            window.setUniversalLeadId = onGetUniversalLeadId;
            const script = document.createElement('script');
            script.id = 'LeadiDscript_campaign';
            script.type = 'text/javascript';
            script.async = true;
            script.src = `https://create.lidstatic.com/campaign/${jornayaToken}.js?snippet_version=2&callback=setUniversalLeadId`;
            document.body.appendChild(script)

            const noscript = document.createElement('noscript');
            const imgPixel = document.createElement('img');
            imgPixel.src = `https://create.leadid.com/noscript.gif?lac=4b1f5928-2127-08fa-4ea5-f0db210f6aae&lck=${jornayaToken}&snippet_version=2`;
            document.body.appendChild(noscript)
            noscript.appendChild(imgPixel)
        }
    }, [jornayaToken, onGetUniversalLeadId, leadId]);

    return null;

}

Jornaya.propTypes = {
    jornayaToken: PropTypes.string.isRequired,
    onGetUniversalLeadId: PropTypes.func.isRequired,
    leadId: PropTypes.string.isRequired
}
 
export default Jornaya;