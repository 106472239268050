import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const PushnamiReact = ({pushnamiToken}) => {

    useEffect(()=>{
        if (typeof window !== "undefined"){

            if (!document.getElementById("pushnami")){
                const script = document.createElement('script');
                script.id='pushnami'
                script.type = 'text/javascript';                
                script.src = `https://api.pushnami.com/scripts/v1/pushnami-adv/${pushnamiToken}`;                
                script.onload = function() {                    
                    window.Pushnami
                        .update()
                        .prompt();
                };
                
                document.getElementsByTagName("head")[0].appendChild(script);
            }
            
        }
    }, [pushnamiToken]);

    return null;

}

PushnamiReact.propTypes = {
    pushnamiToken: PropTypes.string.isRequired,    
}
 
export default PushnamiReact;