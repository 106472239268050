import React, { Component } from 'react';

class Terms extends Component {
    state = {  }
    render() { 
        return ( 
            <div className="content text-left specialPages">
                <div className="card m-3 p-4">                    
                    <div dangerouslySetInnerHTML={{__html: `${this.props.content}`}}></div>
                </div>                
            </div>   
        );
    }
}
 
export default Terms;