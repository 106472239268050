import React, { Component } from 'react';
import Loading from '../Loading/Loading';

import './LoadingPage.css'

class LoadingPage extends Component {
    state = {  }
    render() { 
        return ( 
            <React.Fragment>

                <Loading></Loading>
                        
            </React.Fragment>
        );
    }
}
 
export default LoadingPage;