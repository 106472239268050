import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './Navbar.css';

class Navbar extends Component {

    state = {  }

    getNavbarStyles = (navBackgroundColor) => {
        let style = {backgroundColor: navBackgroundColor}
        return style;
    }

    getLogoClasses = (design) => {
        let classes = "navbar-brand"

        if (design.position === "center"){
            classes = "navbar-brand mx-auto"
        }

        return classes
    }

    getLogo = (design) => {
        let logo = 'Logo';

        if (design.logoImage){
            logo = <img style={{maxHeight: '30px'}} src={design.logoImage} alt={design.logoText} crossOrigin="" />
        } else if (design.logoText.length > 0) {
            logo = design.logoText
        }

        return logo;
    }

    render() { 
        return (             
            <nav className="Toolbar navbar" style={this.getNavbarStyles(this.props.design.nav.backgroundColor)}>
                <Link className={this.getLogoClasses(this.props.design.nav.logo)} to="/" >
                    {/* MacBounty Logo */}
                    {this.getLogo(this.props.design.nav.logo)}
                </Link>                    
            </nav>            
        );
    }

}
 
export default Navbar;