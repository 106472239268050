import React, { Component } from 'react';
import CallToAction from '../templates/CallToAction/CallToAction';
import mapSurveyResponses from '../../../helpers/mapSurveyQuestions';

class TextInt extends Component {

    state = { 
        error: false,
    }

    validate = (questionId, value) =>{
        // Check to make sure it's a parsable number.        
        if (parseInt(value)){
            this.setState({error:false})
            this.props.handleChange(questionId, parseInt(value));
        } else {
            this.setState({error:true})
            this.props.handleChange(questionId, '');
        }        
    }

    render() {

        let {questionId, question, placeholder, response, charLimit, callToAction} = this.props.data;

        return ( 
            <div>
                <h3>
                    <span dangerouslySetInnerHTML={{__html: mapSurveyResponses(question, this.props.fullSurvey)}}></span>
                </h3>                
                <div className="mt-3">
                    <input className="form-control" type="text" maxLength={charLimit > 0 ? charLimit : 250} placeholder={placeholder} onChange={event => this.validate(questionId, event.target.value)} value={response} />
                </div>
                { callToAction !== '' &&
                    <CallToAction lastQuestion={this.props.lastQuestion} nextStep={this.props.nextStep} callToAction={callToAction} getNextButton={this.props.getNextButton} />
                }
            </div>
        );
    }
}
 
export default TextInt;