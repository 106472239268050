import React, { Component } from 'react';
import Countries from '../templates/Countries';
import CallToAction from '../templates/CallToAction/CallToAction';
import mapSurveyResponses from '../../../helpers/mapSurveyQuestions';

class ShortFormAddress extends Component {
    state = { 
        value: !this.props.data.response.country ? '' : this.props.data.response.country
    }

    handleChangeCountry = (event) => {
        this.setState({
            value: event.target.value
        })

        this.props.handleChange(this.props.data.questionId, event.target.value, false, true, 'country');        
    }

    render() { 

        let {questionId, disclaimer, question, placeholder, response, callToAction} = this.props.data;
        let address1;
        let address2;
        let city;
        let state;
        let zipcode;

        if (typeof response === 'object'){
            address1 = !response.address1 ? "" : response.address1;
            address2 = !response.address2 ? "" : response.address2;
            city = !response.city ? "" : response.city;
            state = !response.state ? "" : response.state;
            zipcode = !response.zipcode ? "" : response.zipcode;
        }

        return ( 
            <div>
                {question !== '' &&
                    <div className="alert alert-info">
                        <h3>
                            <span dangerouslySetInnerHTML={{__html: mapSurveyResponses(question, this.props.fullSurvey)}}></span>
                        </h3>
                    </div>
                }
                <div className="row mt-4">
                    <div className="col-md-6">
                        <h3 className="text-left">
                            Address
                        </h3>
                        <div className="mt-3">
                            <input maxLength={250} className="form-control" type="text" onChange={event => this.props.handleChange(questionId, event.target.value, false, true, 'address1')} defaultValue={address1} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h3 className="hide-mobile">
                            &nbsp;
                        </h3>
                        <div className="mt-3">
                            <input maxLength={250} className="form-control" type="text" onChange={event => this.props.handleChange(questionId, event.target.value, false, true, 'address2')} defaultValue={address2} />
                        </div>
                    </div>                    
                </div>
                <div className="mt-3 row">
                    <div className="col-md-6">
                        <h3 className="text-left">
                            City
                        </h3>
                        <div className="mt-3">
                            <input maxLength={250} className="form-control" type="text" onChange={event => this.props.handleChange(questionId, event.target.value, false, true, 'city')} defaultValue={city} />
                        </div>
                    </div>
                    <div className="col-md-6 short-form-group">
                        <h3 className="text-left">
                            State
                        </h3>
                        <div className="mt-3">
                            <input maxLength={250} className="form-control" type="text" onChange={event => this.props.handleChange(questionId, event.target.value, false, true, 'state')} defaultValue={state} />
                        </div>
                    </div>
                </div>
                <div className="mt-3 row">
                    <div className="col-md-6">
                        <h3 className="text-left">
                            Zipcode
                        </h3>
                        <div className="mt-3">
                            <input maxLength={10} className="form-control" type="text" onChange={event => this.props.handleChange(questionId, event.target.value, false, true, 'zipcode')} defaultValue={zipcode} />
                        </div>
                    </div>                                       
                </div>
                <div className="mt-3">
                    <h3 className="text-left">
                        Country
                    </h3>
                    <div className="mt-3">
                        {
                            <select className="form-control" defaultValue={this.state.value} onChange={this.handleChangeCountry}>
                                <Countries />
                            </select>
                        }
                    </div>                    
                </div>
                { callToAction !== '' &&
                    <CallToAction fullSurvey={this.props.fullSurvey} lastQuestion={this.props.lastQuestion} nextStep={this.props.nextStep} callToAction={callToAction} getNextButton={this.props.getNextButton} redirect={placeholder} />
                }
                <div className="mt-5">
                    <p dangerouslySetInnerHTML={{ __html: mapSurveyResponses(disclaimer, this.props.fullSurvey) }} className="text-info text-small text-center"></p>
                </div>
            </div>
        );
    }
}
 
export default ShortFormAddress;