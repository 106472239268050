import React, { Component } from 'react';
import Countries from '../templates/Countries';
import mapSurveyResponses from '../../../helpers/mapSurveyQuestions';

class CountryDropdown extends Component {

    state = { 
        value: !this.props.data.response ? '' : this.props.data.response
    }

    saveAndContinue = async (event) => {
        this.setState({
            value: event.target.value
        })

        await this.props.handleChange(this.props.data.questionId, event.target.value);   
        
        const nextStep = this.props.nextStep

        if (!this.props.lastQuestion){
            setTimeout(function(){
                nextStep(false)
            }, 150)
        }
    }

    render() {

        let {question, response} = this.props.data;

        return (
            <div>
                <h3>
                    <span dangerouslySetInnerHTML={{__html: mapSurveyResponses(question, this.props.fullSurvey)}}></span>
                </h3>
                <div className="mt-3">
                    {
                        <select className="form-control" onChange={this.saveAndContinue} value={this.state.value}>                            
                            <Countries selected={response} />
                        </select>
                    }
                </div>
            </div>
        );        
    }
}
 
export default CountryDropdown;