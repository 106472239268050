import React, { Component } from 'react';
import mapSurveyResponses from '../../../helpers/mapSurveyQuestions';

class Dropdown extends Component {

    state = {  }

    saveAndContinue = async (e, questionId) => {
        await this.props.handleChange(questionId, e.target.value);

        const nextStep = this.props.nextStep

        if (!this.props.lastQuestion){
            setTimeout(function(){
                nextStep(false)
            }, 150)
        }
    }

    render() {

        let {questionId, question, options, response} = this.props.data;

        return ( 
            <div>
                <h3>
                    <span dangerouslySetInnerHTML={{__html: mapSurveyResponses(question, this.props.fullSurvey)}}></span>
                </h3>
                <div className="mt-3">
                    <select className="form-control" value={response} onChange={(e) => {this.saveAndContinue(e, questionId)}}>
                        <option disabled></option>
                        {
                            options.map(option => {
                                return <option key={option.value} value={option.value}>{option.value}</option>
                            })
                        }
                    </select>                    
                </div>
            </div>
        );        
    }
}
 
export default Dropdown;