import React, { Component } from 'react';
import CountryList from '../../../data/countrylist';

class Countries extends Component {
    
    state = { 
        countries: CountryList.countries
    }

    render() { 
        return ( 
            <React.Fragment>
                <option disabled></option>
                { this.state.countries.map(country => 
                    this.props.selected === country.value ?
                        <option key={country.code} value={country.code} selected>{country.name}</option>
                    :
                        <option key={country.code} value={country.code}>{country.name}</option>
                    ) 
                }
            </React.Fragment>
        );
    }
}
 
export default Countries;