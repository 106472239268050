import React, { Component } from 'react';

class Contact extends Component {
    state = {  }
    render() { 
        return ( 
            <div className="content text-left specialPages" style={{height: '546px'}}>
                <div className="card m-3 p-4 h-100 d-flex align-items-center" >                    
                    <div>If you have any questions or concerns, please contact us at: <a href={`mailto:${this.props.support}`}>{this.props.support}</a></div>                    
                </div>
            </div>
        );
    }
}
 
export default Contact;