import React, { Component } from 'react';
import CallToAction from '../templates/CallToAction/CallToAction';
import mapSurveyResponses from '../../../helpers/mapSurveyQuestions';

class Message extends Component {

    state = { 
        submitting: false
    }

    saveAndContinue = () => {

        this.setState({submitting:true})

        const nextStep = this.props.nextStep

        if (!this.props.lastQuestion){
            setTimeout(function(){                
                nextStep(false)
            }, 150)
        }
    }

    render() { 

        let {question, callToAction} = this.props.data;

        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <h3>
                            <span dangerouslySetInnerHTML={{__html: mapSurveyResponses(question, this.props.fullSurvey)}}></span>
                        </h3>
                        { callToAction !== '' &&
                            <CallToAction lastQuestion={this.props.lastQuestion} nextStep={this.props.nextStep} callToAction={callToAction} getNextButton={this.props.getNextButton} />
                        }
                    </div>
                </div>                
            </div>
        );
    }
}
 
export default Message;